<template>
  <v-container>
    <Snackbar :snackbar="snackbar" :snackbartext="snackbartext" @setflasesnackbar="snackbar = false"> </Snackbar>

    <v-data-table
      :headers="headers"
      item-key="uid"
      :show-expand="expandflag"
      :items="vendors"
      :search="search"
      :loading="notloaded"
      loading-text="Loading..."
      sort-by="name"
      class="elevation-1"
      :footer-props="{ prevIcon: 'mdi-arrow-left', nextIcon: 'mdi-arrow-right' }"
      @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
      :items-per-page="roles.itemsPerPage"
    >
      <template v-slot:[`item.contactNumber`]="{ item }">
        {{ convertmobile(item.contactNumber) }}
      </template>

      <template v-slot:[`item.alternateNumber`]="{ item }">
        {{ convertmobile(item.alternateNumber) }}
      </template>

      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Vendors</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details></v-text-field>
          <v-dialog v-model="dialog" max-width="1000px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2 mx-4" v-bind="attrs" v-on="on">Add Vendor</v-btn>
            </template>
            <v-form ref="form" v-model="valid" :lazy-validation="lazy">
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <Alertmsg v-if="alertflag" :alertmsg="alertmsg" @setflasealert="alertflag = false"> </Alertmsg>
                    <v-row>
                      <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                        <v-text-field
                          v-model="editedItem.name"
                          :rules="inputrequired"
                          label="Vendor Name"
                          required
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                        <v-text-field
                          v-model="editedItem.contactPerson"
                          :rules="inputrequired"
                          label="Contact Person"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" xs="12" sm="12" md="4" lg="4">
                        <v-text-field
                          v-model="editedItem.type"
                          label="Vendor Type"
                          :rules="inputrequired"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" xs="12" sm="12" md="3" lg="3">
                        <v-text-field
                          v-model="editedItem.contactNumber"
                          label="Contact Number"
                          :rules="inputrequired"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" xs="12" sm="12" md="3" lg="3">
                        <v-text-field
                          v-model="editedItem.alternateNumber"
                          label="Alternate Number"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" xs="12" sm="12" md="2" lg="2">
                        <v-select
                          v-model="editedItem.status"
                          :items="vendorstatus"
                          :rules="inputrequired"
                          label="Status"
                        >
                        </v-select>
                      </v-col>
                      <v-col cols="12" xs="12" sm="12" md="4" lg="4">
                        <v-text-field v-model="editedItem.email" label="Email"></v-text-field>
                      </v-col>
                      <v-col cols="12" xs="12" sm="12" md="2" lg="2">
                        <v-text-field v-model="editedItem.gstnumber" label="GST Number"></v-text-field>
                      </v-col>
                      <v-col cols="12" xs="12" sm="12" md="2" lg="2">
                        <v-text-field v-model="editedItem.creditperiod" label="Credit Period"></v-text-field>
                      </v-col>
                      <v-col cols="12" xs="12" sm="12" md="3" lg="3">
                        <v-text-field v-model="editedItem.paymentmethod" label="Payment Method"></v-text-field>
                      </v-col>
                      <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                        <v-text-field
                          v-model="editedItem.address"
                          label="Address"
                          :rules="inputrequired"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" xs="6" sm="6" md="6" lg="6">
                        <Upload
                          ref="Vendor"
                          :attachments="editedItem.documents"
                          :storagepath="storagePath_documents"
                          :uploadname="`Vendor document`"
                          :disabled="disabled"
                          @addAttachment="addAttachment"
                          @removeAttachment="removeAttachment"
                        />
                      </v-col>
                      <!-- //code,email,gstnumber,creditperiod,paymentmethod-->
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                  <v-btn color="blue darken-1" v-if="!spinner" :disabled="!valid" text @click="saveitem">Save</v-btn>
                  <Spinner v-if="spinner" />
                </v-card-actions>
              </v-card>
            </v-form>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-row>
            <v-col cols="12" xs="12" sm="12" md="1" lg="1">
              <h4>Code</h4>
              {{ item.code }}
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="3" lg="3">
              <h4>Email</h4>
              {{ item.email }}
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="3" lg="3">
              <h4>GST Number</h4>
              {{ item.gstnumber }}
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="3" lg="3">
              <h4>Credit Period</h4>
              {{ item.creditperiod }}
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="2" lg="2">
              <h4>Payment Method</h4>
              {{ item.paymentmethod }}
            </v-col>

            <v-col cols="12" xs="12" sm="12" md="4" lg="4">
              <h4>Address</h4>
              {{ item.address }}
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="4" lg="4">
              <h4>Files</h4>
              <Attachment
                v-for="(file, index) in item.documents"
                :key="file.filename"
                :file="file"
                :index="index"
                :close="false"
                :color="'green'"
              />
            </v-col>
          </v-row>
        </td>
      </template>

      <template v-if="editflag" v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click.stop="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click.stop="deleteItem(item)"> mdi-delete </v-icon>
      </template>
      <template v-slot:no-data>
        <div><h2 class="blue">No data Found- Please add Vendor</h2></div>
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import { vendorRef } from "@/fb"
import { convertAll } from "@/mixins/convertall.js"
import { collectionsOperation } from "@/mixins/collectionsOperations.js"
import Upload from "@/components/common/Upload.vue"
import Attachment from "@/components/common/Attachment.vue"
import Snackbar from "@/components/common/snackbar.vue"
import Alertmsg from "@/components/common/Alertmsg.vue"
import Spinner from "@/components/common/spinner.vue"
import {mapState} from "vuex"
export default {
  name: "Vendor",
  mixins: [convertAll, collectionsOperation],
  components: { Upload, Attachment, Snackbar, Alertmsg, Spinner },
  //props:['roles'],
  data: () => ({
    dialog: false,
    menuls: false,
    menuns: false,
    search: "",
    domain: "",
    notloaded: true,
    lazy: false,
    valid: true,
    expandflag: true,
    headers: [
      {
        text: "Name",
        align: "start",
        sortable: true,
        value: "name",
      },
      { text: "Code", value: "code" },
      { text: "Type", value: "type" },
      { text: "Contact Person", value: "contactPerson" },
      { text: "Contact Number", value: "contactNumber" },
      { text: "Alternate Number", value: "alternateNumber" },
      { text: "Status", value: "status" },
      // { text: 'Address', value: 'address' },
      { text: "Actions", value: "actions", sortable: false },
    ],

    inputrequired: [(v) => !!v || "Input is required"],
    vendorstatus: ["Active", "Inactive"],
    vendors: [],
    editedIndex: -1,

    editedItem: {
      name: null,
      type: null,
      contactPerson: null,
      contactNumber: null,
      alternateNumber: null,
      status: null,
      address: null,
      domain: null,
      code: null,
      email: null,
      gstnumber: null,
      creditperiod: null,
      paymentmethod: null,
      createdon: null,
      modifiedon: null,
     
      documents: [],
    }, // wi
    defaultItem: {
      name: null,
      type: null,
      contactPerson: null,
      contactNumber: null,
      alternateNumber: null,
      status: null,
      address: null,
      domain: null,
      code: null,
      email: null,
      gstnumber: null,
      creditperiod: null,
      paymentmethod: null,
      createdon: null,
      modifiedon: null,
      documents: [],
     
    }, // wi
    editflag: false,
    backmenu: "admin",

    snackbar: false,
    alertflag: false,
    alertmsg: "",
    snackbartext: "",
    roles: "",
    spinner: false,
  }), // end of data

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add Vendor" : "Edit Vendor"
    },
    storagePath_documents() {
      var documentstoragepath = "vendor/" + this.domain + "_" + this.editedItem.name + "_"
      return documentstoragepath
    },
    ...mapState({
      currentDomain: state=> state.userProfile.domain
    })
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
  },
  destroyed() {
    var unsubscribe = vendorRef
    .onSnapshot(() => {
    });

   unsubscribe();

  },

    mounted () {
        vendorRef.where("domain", "==", this.currentDomain)
         .onSnapshot( (snapshot) => {
        
           if(!this.notloaded)
          snapshot.docChanges().forEach((change) => {
       
             this.updaterealtimedata(this.vendors,change)  
          })// end of for each
        })// end of snapshot
    }, // end of mounted

  async created() {
    this.roles = await this.$store.state.userProfile
    console.log("Vendor crated", this.roles)
    if (this.roles) {
      if (this.roles.role.sysadmin || this.roles.role.readuser) {
        if (this.roles.role.sysadmin) {
          this.editflag = true
        }
        this.domain = this.roles.domain
        this.initialize()
      } else {
        alert("Not authorized to access this page , kindly contact support")
        this.$router.replace({ name: this.roles.menufrom })
      }
    } else {
      alert("Not authorized to access this page , kindly contact support")
      this.$router.replace({ name: "/login" })
    }
  },

  methods: {
    async initialize() {
      this.vendors = []
      /*await vendorRef.where("domain","==",this.domain).get()
            .then(querySnapshot => {
              querySnapshot.forEach(doc => {
               var objIndex=this.vendors.findIndex(obj=> obj.uid==doc.id)
                   if(objIndex<0){
                if(!doc.data().alternateNumber)
                doc.data().alternateNumber=doc.data().contactNumber;

                this.vendors.push({
                   uid:doc.id,
                  ...doc.data()
                });
                }
              })

              this.notloaded=false;
           })*/
      // var newvendorRef="new"+vendorRef

      var ret = await this.loadInitialData(vendorRef, this.domain, this.vendors)
     
      this.notloaded = false
      if (ret != true) {
        this.alertflag = true
        this.alertmsg = ret
      }
    },

    editItem(item) {
      this.editedIndex = this.vendors.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    addAttachment(options, fileDetails) {
      console.log("inside addAttachmentt options", options)
      console.log("inside addAttachment fileDetails", fileDetails)
      this.editedItem.documents.push({ ...fileDetails })
    },
    removeAttachment(options, fileDetails) {
      console.log("inside removeAttachment options", options)
      console.log("inside removeAttachment fileDetails", fileDetails)
      let files = this.editedItem.documents.filter((v) => v.filename !== fileDetails.filename)
      this.editedItem.documents = [...files]
    },

    async deleteItem(item) {
      const deletemsg = "Are you sure you want to delete this Vendor?" + item.name
      var Ret = await this.commonDeleteItem(item, this.vendors, vendorRef, deletemsg)
      console.log("Return from delete Item", Ret)
      if (Ret == true) {
        this.snackbar = true
        this.snackbartext = "The vendor has been deleted successfully"
      } else {
        this.alertflag = true
        this.alertmsg = "Error while delete" + Ret
      }
    },

    close() {
      this.dialog = false
      this.spinner = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    async saveitem() {
      this.spinner = true
      if (this.editedIndex > -1) {
        Object.assign(this.vendors[this.editedIndex], this.editedItem)

        this.editedItem.modifiedon = new Date().toISOString().substring(0, 10)
        console.log("inside saveite update", this.editedItem)
        try {
          await vendorRef.doc(this.editedItem.uid).set({
            ...this.editedItem,
          })
          this.snackbar = true
          ;(this.snackbartext = "Vendor details updated successfully"), this.close()
        } catch (error) {
          this.alertflag = true
          this.alertmsg = "Error while updating" + error
          this.spinner = false
        }
      } // end of if
      else {
        this.editedItem.domain = this.domain
        console.log("inside new item add", this.editedItem)
        var queryresult = this.vendors.filter((obj) => obj.name == this.editedItem.name)
        if (queryresult.length == 0) {
          if (this.editedItem.alternateNumber == null) {
            this.editedItem.alternateNumber = this.editedItem.contactNumber
          }
          this.editedItem.createdon = new Date().toISOString().substring(0, 10)
          var maxcode = 0
          for (var i = 0; i < this.vendors.length; ++i) {
            if (parseInt(this.vendors[i].code) > maxcode) maxcode = parseInt(this.vendors[i].code)
          }
          maxcode++
          this.editedItem.code = maxcode
          var retvalue = await this.addcollection(vendorRef, this.vendors, this.editedItem)
          /*   await vendorRef.add(this.editedItem).then(docRef => {
                    this.editedItem.uid=docRef.id;
                    var objIndex=this.vendors.findIndex(obj=> obj.uid==this.editedItem.uid)
                      if(objIndex<0)
                       this.vendors.push(this.editedItem);
                       this.snackbar=true;
                        this.snackbartext='Vendor added successfully',
                    this.close()*/
          console.log("value from addcollection", retvalue)
          if (retvalue == true) {
            this.snackbar = true
            this.snackbartext = "Vendor added successfully"

            this.close()
          } else {
            this.alertflag = true
            this.alertmsg = retvalue
            this.spinner = false
          }
        } // end of queryresult ==0
        else {
          this.alertflag = true
          this.alertmsg = "Vendor already exists"
          this.spinner = false
        }
      }
    },
  },
}
</script>
